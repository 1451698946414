import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Step,
  StepContent,
  Switch,
  TextField
} from '@mui/material'
import { PhoneTextField, TitleSelect } from 'components/inputs'
import { validatePhoneNumber } from 'components/inputs/PhoneTextField/PhoneFieldHelper'
import StepperButton from 'components/steppers/StepperButton'
import React from 'react'
import RequiredTextField from '../../../inputs/RequiredTextField'
import EmailTextField from 'components/inputs/EmailTextField'

/**
 * Step to capture information about the primary contact.
 */
const PrimaryContactStep = ({
  activeStep,
  index,
  installation,
  onActiveStep,
  onChange,
  onValid,
  ...rest
}) => {
  /**
   * 🔔 Slightly hacky here: these are a set of keys which should correspond
   * with the `installation.notes.carer.days` properties. This just allows us to
   * order the object properties propertly.
   */
  const daysOfWeek = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']

  React.useEffect(() => {
    // Decides whether the next button on this step is enabled
    if (activeStep === index) {
      const primaryNumberValid = validatePhoneNumber(
        installation?.account?.customer?.phone1
      )

      if (!installation?.notes?.hasCarer) {
        onValid(primaryNumberValid)
      } else {
        const carerNumberValid = validatePhoneNumber(
          installation?.notes?.carer?.phone
        )
        const carerRequiredFields = [
          installation?.notes?.carer?.firstName,
          installation?.notes?.carer?.surname
        ]
        const carerRequiredValid = !carerRequiredFields.some(e => e === '')
        onValid(primaryNumberValid && carerNumberValid && carerRequiredValid)
      }
    }
  }, [activeStep, index, installation, onValid])

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='Primary contact information'
        onActiveStep={onActiveStep}
      />
      <StepContent>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <PhoneTextField
              label='Main contact number'
              onChange={val => onChange('account.customer.phone1', val)}
              required
              value={installation?.account?.customer.phone1}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <PhoneTextField
              label='Mobile phone number'
              onChange={val => onChange('account.customer.mobile', val)}
              value={installation?.account?.customer.mobile}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <PhoneTextField
              label='Any other phone number'
              onChange={val => onChange('account.customer.phone2', val)}
              value={installation?.account?.customer.phone2}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <EmailTextField
              label='Email address'
              onChange={val => onChange('account.customer.email', val)}
              value={installation?.account?.customer.email}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              fullWidth
              label='Language, if not English'
              onChange={val => onChange('notes.language', val)}
              value={installation?.notes?.language}
              variant='outlined'
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              fullWidth
              label='Key safe number'
              onChange={val => onChange('notes.keySafe', val)}
              value={installation?.notes?.keySafe}
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch color='primary' name='checked' />}
              label='Does a carer attend the occupant(s)?'
              onChange={val => onChange('notes.hasCarer', val)}
              checked={installation?.notes?.hasCarer}
            />
          </Grid>

          {installation?.notes?.hasCarer && (
            <>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label='Type'
                  onChange={val => onChange('notes.carer.isOrganisation', val)}
                  required
                  select
                  value={installation?.notes?.carer.isOrganisation}
                  variant='outlined'
                >
                  <MenuItem key='individual' value={false}>
                    Individual
                  </MenuItem>
                  <MenuItem key='individual' value='true'>
                    Organisation
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item sm={8} />
              {!installation?.notes?.carer.isOrganisation && (
                <>
                  <Grid item sm={4} xs={12}>
                    <TitleSelect
                      onChange={e => onChange('notes.carer.title', e)}
                      required
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item sm={4} xs={12}>
                    <RequiredTextField
                      label='First name'
                      onChange={val => onChange('notes.carer.firstName', val)}
                      value={installation?.notes?.carer.firstName}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <RequiredTextField
                      label='Surname'
                      onChange={val => onChange('notes.carer.surname', val)}
                      value={installation?.notes?.carer.surname}
                    />
                  </Grid>
                </>
              )}

              {installation?.notes.carer.isOrganisation && (
                <Grid item sm={4} xs={12}>
                  <RequiredTextField
                    label='Organisation name'
                    onChange={e => onChange('notes.carer.organisationName', e)}
                    value={installation?.notes?.carer.organisationName}
                  />
                </Grid>
              )}

              <Grid item sm={6} xs={12}>
                <PhoneTextField
                  label='Phone number'
                  onChange={val => onChange('notes.carer.phone', val)}
                  required
                  value={installation?.notes?.carer.phone}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>
                    How many care visits per week?
                  </FormLabel>

                  <FormGroup aria-label='Days of week' row>
                    {daysOfWeek.map(day => (
                      <FormControlLabel
                        control={<Checkbox color='primary' />}
                        key={day}
                        label={day.toUpperCase()}
                        labelPlacement='top'
                        onChange={v => onChange(`notes.carer.days.${day}`, v)}
                        checked={installation?.notes?.carer.days[day]}
                        value={installation?.notes?.carer.days[day]}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </StepContent>
    </Step>
  )
}

export default PrimaryContactStep
