import { CheckCircle } from '@mui/icons-material'
import { Grid, InputAdornment, TextField } from '@mui/material'
import { RequiredTextField, LocationSelect } from 'components/inputs'

/**
 * Input form for capturing sensor device info.
 */
const SensorForm = ({ device, index, onChange, envosenseUniqueHelper }) => {
  const validCheck = value =>
    /^[a-zA-Z0-9]{8}$/.test(value) && !envosenseUniqueHelper

  const serialMatch = device?.verifySerialNumber === device?.serialNumber

  const helperText =
    envosenseUniqueHelper || 'Should be 8 characters (printed on the device)'

  let verifyHelperText = ''
  if (!validCheck(device?.verifySerialNumber)) {
    verifyHelperText = helperText
  } else if (!serialMatch) {
    verifyHelperText = 'Envosense serial numbers should match'
  } else {
    verifyHelperText = ''
  }

  const validAdornment = (
    <InputAdornment position='end'>
      <CheckCircle color='success' />
    </InputAdornment>
  )

  /**
   * the event object is set not to take its default action unless explicitly handled,
   * used to the prevent the paste command from doing so. This forces the user to
   * type the serial number into the verify box
   */

  const handleChange = e => {
    e.preventDefault()
  }

  return (
    <Grid container item spacing={2}>
      <Grid item sm={6} xs={12}>
        <RequiredTextField
          autoComplete='off'
          error={!validCheck(device?.serialNumber)}
          fullWidth
          InputProps={{
            endAdornment: validCheck(device?.serialNumber) && validAdornment
          }}
          helperText={validCheck(device?.serialNumber) ? '' : helperText}
          label='Serial number'
          id={`serialNumber${index}`}
          name='serialNumber'
          onChange={onChange}
          required
          value={device?.serialNumber}
          variant='outlined'
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <RequiredTextField
          autoComplete='off'
          error={!validCheck(device?.verifySerialNumber) || !serialMatch}
          fullWidth
          InputProps={{
            endAdornment:
              validCheck(device?.verifySerialNumber) &&
              serialMatch &&
              validAdornment
          }}
          helperText={verifyHelperText}
          id={`verifyEnvoNumber${index}`}
          label='Verify Serial number'
          name='verifySerialNumber'
          onChange={onChange}
          onPaste={handleChange}
          required
          value={device?.verifySerialNumber}
          variant='outlined'
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <LocationSelect
          fullWidth
          name='roomLocation'
          id={`EnvosenseLocation${index}`}
          onChange={onChange}
          showRequired={!device?.roomLocation}
          value={device?.roomLocation}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          id={`envosenseNotes${index}`}
          name='notes'
          label='Notes'
          onChange={onChange}
          value={device?.notes}
          variant='outlined'
        />
      </Grid>
    </Grid>
  )
}

export default SensorForm
