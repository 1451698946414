import { InputAdornment, Link, MenuItem } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useRef } from 'react'
import RequiredTextField from 'components/inputs/RequiredTextField'

/**
 * A user-friendly input comopnent for entering a date. It uses a text field,
 * with a mask applied, to help with user understanding of the date format, and
 * to aid validation.
 */
const TitleSelect = ({ onChange = () => {}, value = 'Mr', ...rest }) => {
  const textInput = useRef(null)
  const staticTitles = ['Mr', 'Mrs', 'Miss', 'Ms', 'No title']
  const otherTitle = 'Other'
  const titles = [...staticTitles, otherTitle]

  const [title, setTitle] = React.useState(value)
  const isSelect = staticTitles.some(v => v === title) || title === ''
  const [select, setSelect] = React.useState(isSelect)

  React.useEffect(() => {
    setSelect(isSelect)
  }, [value, title, isSelect])

  React.useEffect(() => {
    !select && textInput.current.focus()
    // select && handleReset()
  }, [select])

  const handleChange = (event = { target: { value: '' } }) => {
    const { value } = event?.target
    setTitle(value !== otherTitle ? value : '')
    onChange(event)
  }

  const handleReset = event => {
    setTitle(titles[0])
  }

  const adornment = !select && (
    <Link component='button' onClick={handleReset}>
      <ArrowBack />
    </Link>
  )

  const menuItems = titles.map(title => (
    <MenuItem key={title} value={title}>
      {title}
    </MenuItem>
  ))

  const inputProps = {
    startAdornment: (
      <InputAdornment position='start'>{adornment}</InputAdornment>
    )
  }

  return (
    <>
      <RequiredTextField
        {...rest}
        fullWidth
        helperText='E.g. Mr, Mrs, Miss'
        inputRef={textInput}
        InputProps={inputProps}
        label='Title'
        onChange={handleChange}
        select={select}
        value={title}
      >
        {menuItems}
      </RequiredTextField>
    </>
  )
}

export default TitleSelect
