import { blue, green, grey, orange } from '@mui/material/colors'

const status = {
  WAITING: 1,
  INTERVENTION: 2,
  ACTIVE: 3,
  SUSPENDED: 4,
  CLOSED: 5,
  AWAY: 6,
  REJECTED: 7,
  VACANT: 8,
  HOSPITAL_SHORT: 9,
  HOSPITAL_LONG: 10,
  HOLIDAY: 11,
  HOSPITAL_KNOWN: 12,
  BOOKED_OUT: 13
}

const accountType = {
  HP_LITE: 1,
  HP_FULL: 2,
  TEST: 3,
  BASIC: 4
}

const getAccountTypeString = type => {
  switch (type) {
    case 1:
      return 'HP Lite'
    case 2:
      return 'HP Full'
    case 3:
      return 'Test'
    case 4:
      return 'Basic'
    default:
      return 'N/A'
  }
}

const getStatusString = status => {
  switch (status) {
    case 1:
      return 'Waiting'
    case 2:
      return 'Intervention'
    case 3:
      return 'Active'
    case 4:
      return 'Suspended'
    case 5:
      return 'Closed'
    case 6:
      return 'Away'
    case 7:
      return 'Rejected'
    case 8:
      return 'Vacant'
    case 9:
      return 'Hospital (short stay)'
    case 10:
      return 'Hospital (long stay)'
    case 11:
      return 'Holiday'
    case 12:
      return 'Hospital (indefinite'
    case 13:
      return 'Booked out'
    default:
      return 'N/A'
  }
}

const getStatusColor = status => {
  switch (status) {
    case 1:
      return blue[500]
    case 2:
    case 4:
    case 5:
    case 7:
    case 8:
      return grey[500]
    case 3:
      return green[500]
    case 6:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
      return orange[500]
    default:
      return grey[500]
  }
}

export {
  getAccountTypeString,
  getStatusColor,
  getStatusString,
  status,
  accountType
}
