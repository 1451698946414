/**
 * Helper to wrangee CLis and phone numbers into the correct slots.
 */
class CliHelper {
  constructor ({ cli, phone1, phone2, mobile } = {}) {
    this.cli = cli
    this.phone1 = phone1
    this.phone2 = phone2
    this.mobile = mobile
  }

  sanitise (val) {
    return val?.replace(/\D/g, '')
  }

  isMobile (val) {
    return /^(07|0046|\+447|\+46)/.test(this.sanitise(val))
  }

  static isMobile (val) {
    return new CliHelper().isMobile(val)
  }

  /**
   * Use some very bespoke logic to determine how CLI should be stored in
   * relation to customer contact nmbers. This is a very expensive, icky
   * function implementation, which should be made more robust using this
   * helper classes granular methods.
   */
  static expensiveWrangle ({ cli, phone1, phone2, mobile, prevCli }) {
    const sanitizedCli = prevCli?.replace?.(/\D/g, '')

    /**
     * If we find a previous CLI match, then clear it out.
     */
    if (phone1?.replace(/\D/g, '') === sanitizedCli) phone1 = undefined
    if (phone2?.replace(/\D/g, '') === sanitizedCli) phone2 = undefined
    if (mobile?.replace(/\D/g, '') === sanitizedCli) mobile = undefined

    /**
     * Shake down any empty values, so we can do readable ordering.
     */
    const stack = [phone1, phone2, mobile].filter(val => val)
    phone1 = stack[0]
    phone2 = stack[1]
    mobile = stack[2]

    /**
     * This is the default baseline response, and ensures we're catering for
     * legacy landline installs. Always sanitise the CLI, other numbers don't
     * matter.
     */
    const response = {
      cli: cli?.replace(/\D/g, ''),
      phone1: cli,
      phone2,
      mobile
    }

    /**
     * Legacy landline installs aren't worth the effort of reassinging contact
     * numbers, so we've done enough to cater for thier existence.
     */
    if (!this.isMobile(cli)) return response

    /**
     * If there's a contact number in phone1, then we keep it there, and move
     * cli to phone2. Reason: we always want landline or mobile in phone1.
     */
    if (phone1) {
      response.phone1 = phone1
      response.phone2 = cli
    }

    /**
     * Shuffle along any other number which was in phone2
     */
    if (phone2) {
      response.mobile = phone2
    }

    return response
  }
}

export default CliHelper
